
















import { defineComponent, SetupContext, onMounted, ref } from '@vue/composition-api'
import axios from 'axios'
import { headerWithAccessToken } from '@/libs'

export default defineComponent({
  setup(_, ctx: SetupContext) {
    const state = ref<'idle' | 'successful' | 'invalid'>('idle')
    const acceptChanges = async (id: string, key: string) => {
      const { uri } = ctx.root.$appConfig.endpoints.patchEmail
      const endpoint = uri.replace(':id', id)
      console.log(headerWithAccessToken(endpoint))
      await axios
        .patch(
          endpoint,
          { key },
          {
            headers: headerWithAccessToken(endpoint),
          }
        )
        .then(() => {
          state.value = 'successful'
        })
        .catch(() => {
          state.value = 'invalid'
        })
    }

    onMounted(async () => {
      const { query } = ctx.root.$route
      const id = query.id || false
      const key = query.key || false
      if (!id || !key) {
        state.value = 'invalid'
        return
      }
      await acceptChanges(id as string, key as string)
    })
    return {
      state,
    }
  },
})
